import { IonBackButton, IonButton, IonButtons, IonContent, IonDatetime, IonDatetimeButton, IonHeader, IonImg, IonItem, IonLabel, IonList, IonModal, IonPage, IonProgressBar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIonViewDidEnter, CreateAnimation } from '@ionic/react';
import { v4 as uuidv4 } from "uuid";
import './Request.css';
import Menu from './components/Menu';
import supabase from './supabase/supabaseClient';

const RequestPage: React.FC = () => {

  const successAnimation = useRef<any>(null);

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("type") !== "parent" || !process.env.REACT_APP_request) {
      history.push("/account");
    }
  });

  useIonViewDidEnter(() => {
    getStudentData();
  });

  const [students, setStudents] = useState([]);
  const [requestReason, setRequestReason] = useState("");
  const [dateTime, setDateTime] = useState("");
  const uid = localStorage.getItem("uid");
  const [progress, setProgress] = useState(0);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [student, setStudent] = useState(uuidv4());
  const [requestType, setRequestType] = useState("dismissal");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [schoolId, setSchoolId] = useState("");

  async function getStudentData() {
    let { data, error } = await supabase
      .from("student_accounts")
      .select("fname, lname, uid, school_id")
      .in("uid", JSON.parse(localStorage.getItem("students")));
    if (data.length > 0) {
      // Map the data to an array of student objects
      const studentData = data.map((student) => ({
        uid: student.uid,
        fname: student.fname,
        lname: student.lname,
        school_id: student.school_id,
      }));
  
      // Update the state variable with the new array
      setStudents(studentData);
    } else {
      console.warn("No matching rows found/invalid account type");
      console.error(error);
    }
  }

  const handleRequestTypeChange = e => {
    setRequestType(e.target.value);
  }

  const handleRequestReasonChange = e => {
    setRequestReason(e.target.value)
  };

  const handleStudentChange = e => {
    const selectedStudentId = e.target.value;
    setStudent(selectedStudentId);

    const selectedStudent = students.find(student => student.uid === selectedStudentId);
    if (selectedStudent) {
      setSchoolId(selectedStudent.school_id);
    } else {
      setSchoolId("");
      console.warn("no school ID");
    }
  };

  const handleDateTimeChange = e => {
    setDateTime(e.target.value)
  };

  const submitData = async () => {
    if (student !== ""){
      if (dateTime !== ""){
        setProgress(1);        
        const { error } = await supabase
          .from("requests")
          .insert({ id: requestType+"-u:"+uid+"-d:"+dateTime, type: requestType, datetime: dateTime, reason: requestReason, status: "created", requester: uid, recipient: student, school_id: schoolId })
        if (error){
          console.error(error);
          setShowErrorToast(true);
        } else {
          setShowSuccessToast(true);
          setShowSuccessModal(true);          
          setProgress(0);
          setRequestReason("");
          setDateTime("");
          setStudent(uuidv4());
          setRequestType("dismissal");
          setTimeout(() => {
            setShowSuccessModal(false);
          },1500);
        }                        
      }else{
        setShowErrorToast(true);
      }
    }else{
      setShowErrorToast(true);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>New Request</IonTitle>
          <IonProgressBar type="determinate" value={progress}></IonProgressBar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonSegment onIonChange={handleRequestTypeChange} value={requestType}>
          <IonSegmentButton value="dismissal">
            <IonLabel>Dismissal</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="check-in">
            <IonLabel>Check-In</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <IonList>
          <IonItem>
            <IonSelect interface="popover" placeholder="Select Student" id="student" value={student} onIonChange={handleStudentChange}>
              {students.map((student) => (
                <IonSelectOption key={student.uid} value={student.uid}>
                  {student.fname} {student.lname}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonSelect interface="popover" placeholder="Select Dismissal Type" id="dismissalType" value={requestReason} onIonChange={handleRequestReasonChange}>
              <IonSelectOption>Doctor</IonSelectOption>
              <IonSelectOption>Personal Day</IonSelectOption>
              <IonSelectOption>Vacation</IonSelectOption>
              <IonSelectOption>Other</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonDatetime onIonChange={handleDateTimeChange} id="datetime"></IonDatetime>
          </IonItem>
          <IonButton  fill="outline"onClick={submitData} expand="block">Submit</IonButton>
          <IonToast isOpen={showSuccessToast} onDidDismiss={() => setShowSuccessToast(false)} message="Submitted successfully! You'll receive a notification when your student's pass is approved." duration={2000} />
          <IonToast isOpen={showErrorToast} onDidDismiss={() => setShowErrorToast(false)} message="Error, please make sure that all fields are filled out and then try again. Make sure that you've selected a date and time. Please contact your school's admin if error persists." duration={2000} />
        </IonList>
        <IonModal isOpen={showSuccessModal} onDidPresent={() => successAnimation.current.animation.play()} onDidDismiss={() => setShowSuccessModal(false)} animated={false} className="transparent-modal">
          <CreateAnimation
            ref={successAnimation}
            duration={1500}
            iterations={1}
            keyframes={[
              { offset: 0, transform: 'scale(0)' },
              { offset: 0.20, transform: 'scale(1.375)' },
              { offset: 0.25, transform: 'scale(1.5)' },
              { offset: 0.75, transform: 'scale(1.5)' },
              { offset: 1, transform: 'scale(0)' },
            ]}
          >
            <IonImg
              alt="unknown image"
              src="/assets/ad-check.png"
              style={{ width: '100px', height: '100px' }}
            />
          </CreateAnimation>
        </IonModal>
        <Menu/>
      </IonContent>
    </IonPage>
  );
};

export default RequestPage;