import React from 'react';
import {
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
} from '@ionic/react';
import {
  chevronUpOutline,
  chevronUpSharp,
  addCircleOutline,
  addCircleSharp,
  personOutline,
  personSharp,
  copyOutline,
  copySharp,
  checkmarkDoneOutline,
  checkmarkDoneSharp,
} from 'ionicons/icons';

const Menu = () => {
  return (
    <>
      {localStorage.getItem("type") === "parent" && (
        <IonFab slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton>
            <IonIcon ios={chevronUpOutline} md={chevronUpSharp}></IonIcon>
          </IonFabButton>
          <IonFabList side="top">
            {process.env.REACT_APP_request && (
              <IonFabButton routerLink="/request">
              <IonIcon ios={addCircleOutline} md={addCircleSharp}></IonIcon>
            </IonFabButton>
            )}       
            {process.env.REACT_APP_attendance && (
              <IonFabButton routerLink="/attendance">
                <IonIcon ios={checkmarkDoneOutline} md={checkmarkDoneSharp}></IonIcon>
              </IonFabButton>
            )}    
            <IonFabButton routerLink="/account">
              <IonIcon ios={personOutline} md={personSharp}></IonIcon>
            </IonFabButton>
          </IonFabList>
        </IonFab>
      )}
      {localStorage.getItem("type") === "student" && (
        <IonFab hidden={localStorage.getItem("type") !== "student"} slot="fixed" vertical="bottom" horizontal="end">
          <IonFabButton>
            <IonIcon ios={chevronUpOutline} md={chevronUpSharp}></IonIcon>
          </IonFabButton>
          <IonFabList side="top">
            {process.env.REACT_APP_pass && (
              <IonFabButton routerLink="/pass">
                <IonIcon ios={copyOutline} md={copySharp}></IonIcon>
              </IonFabButton>
            )}
            {process.env.REACT_APP_attendance && (
              <IonFabButton routerLink="/attendance">
                <IonIcon ios={checkmarkDoneOutline} md={checkmarkDoneSharp}></IonIcon>
              </IonFabButton>
            )}
            <IonFabButton routerLink="/account">
              <IonIcon ios={personOutline} md={personSharp}></IonIcon>
            </IonFabButton>
          </IonFabList>
        </IonFab>
      )}
    </>
  );
};

export default React.memo(Menu);