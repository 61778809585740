import { IonList, IonButton, IonPage, IonHeader, IonTitle, IonToolbar, IonContent } from '@ionic/react';
import Menu from './components/Menu';
import supabase from './supabase/supabaseClient';
import { useHistory } from 'react-router';
import { useEffect } from 'react';

const AccountPage: React.FC = () => {

  let history = useHistory();
  
  const SignOut = async () => {
    const { error } = await supabase.auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
    if (error) {
      console.error(error);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <h1>Welcome to {process.env.REACT_APP_name}!</h1>
        <h2>Account Information</h2>
        <h4>First Name: {localStorage.getItem("fname")}</h4>
        <h4>Last Name: {localStorage.getItem("lname")}</h4>
        <h4>Email: {localStorage.getItem("email")}</h4>
        {localStorage.getItem("type") === "parent" && (
          <h4>Phone Number: {localStorage.getItem("phone")}</h4>
        )}
        <h1>Manage Your Account</h1>
        {localStorage.getItem("type") === "parent" && (
          <div>
            <IonList>
              <IonButton fill="outline" expand="block" routerLink="/updatephone">Update Phone Number</IonButton>
            </IonList>
            <IonList>
              <IonButton fill="outline" expand="block" routerLink="/linkstudent">Link Student</IonButton>
            </IonList>
          </div>
        )}
        {localStorage.getItem("provider") === "email" && (
          <IonList>
            <IonButton fill="outline" expand="block" routerLink="/updatepassword">Update Password</IonButton>
          </IonList>
        )}
        <IonList>
          <IonButton fill="outline" expand="block" onClick={SignOut}>Log Out</IonButton>
        </IonList>
        {localStorage.getItem("type") === "parent" && (
          <div>
            <IonList>
              <IonButton fill="outline" expand="block" routerLink="/deleteaccount">Delete Account</IonButton>
            </IonList>
          </div>
        )}
        <h1>Other</h1>
        <IonList>
          <IonButton fill="outline" expand="block" routerLink="/about">About App</IonButton>
        </IonList>
        <Menu/>
      </IonContent>
    </IonPage>
  );
};

export default AccountPage;