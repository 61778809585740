import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonPage, IonTitle, IonToast, IonToolbar, useIonActionSheet, useIonViewDidEnter } from '@ionic/react';
import supabase from './supabase/supabaseClient';
import { useEffect, useState } from 'react';
import React from 'react';
import { useHistory } from 'react-router-dom';

const UpdatePasswordPage: React.FC = () => {
  
  useEffect(() => {
    sendOTPEmail();
  }, []);

  const history = useHistory();

  const [doPasswordChange, setDoPasswordChange] = useState(false);
  const [password, setPassword] = useState("");
  const [OTP, setOTP] = useState("");
  const [showFieldToast, setShowFieldToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  const handleOTPChange = e => {
    setOTP(e.target.value);
  };

  const checkOTP = e => {
    if (OTP.length === 6){
      setDoPasswordChange(true);
    }else{
      setShowFieldToast(true);
    }
  };

  async function sendOTPEmail() {  
    const { data, error } = await supabase.auth.reauthenticate();
  }

  async function resetPassword() {
    const { data, error } = await supabase.auth.updateUser({password: password, nonce: OTP})
    if (error){
      setShowErrorToast(true);
    } else {
      SignOut();
    }
  }

  const SignOut = async () => {
    const { error } = await supabase.auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
    if (error) {
      console.error(error);
    } else {
      history.push("./")
      window.location.reload();
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Update Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {!doPasswordChange ? (
          <div>
            <h1>Please check your email for a verification code.</h1>
              <IonItem>
                <IonLabel position="floating">One-Time Code</IonLabel>
                <IonInput type="number" id="OTP" inputmode="numeric" value={OTP} enterkeyhint="next" onIonChange={handleOTPChange}></IonInput>
              </IonItem>
              <IonButton  fill="outline"onClick={checkOTP} expand="block">Check One-Time Code</IonButton> 
          </div>
        ) : (
          <div>
            <div>
              <IonItem>
                <IonLabel position="floating">Password</IonLabel>
                <IonInput type="password" id="password" pattern="password" value={password} enterkeyhint="next" onIonChange={handlePasswordChange}></IonInput>
              </IonItem>
              <IonButton  fill="outline"onClick={resetPassword} expand="block">Reset Password</IonButton> 
            </div>
          </div>
        )}
        <IonToast isOpen={showErrorToast} onDidDismiss={() => setShowErrorToast(false)} message="Unknown error. Please contact your school's admin before trying again." duration={2000} />
        <IonToast isOpen={showFieldToast} onDidDismiss={() => setShowFieldToast(false)} message="Error. Please make sure that you entered a valid 6-digit One-Time Code." duration={2000} />
        </IonContent>
    </IonPage>
  );
};

export default React.memo(UpdatePasswordPage);