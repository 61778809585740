import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonImg, IonPage, IonTitle, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Menu from './components/Menu';
import supabase from './supabase/supabaseClient';

const PassPage: React.FC = () => {

  useIonViewDidEnter(() => {
    setAuto(true);
  })

  const history = useHistory();

  useEffect(() => {
    if (auto){
      getPass();
    }
    if (localStorage.getItem("type") !== "student" || !process.env.REACT_APP_pass) {
      history.push("/account");
    }
  });

  const uid = localStorage.getItem("uid");
  const today = new Date();
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [date, setDate] = useState("");
  const [fname, setFname] = useState("");
  const [key, setKey] = useState("");
  const [lname, setLname] = useState("");
  const [reason, setReason] = useState("");
  const [time, setTime] = useState("");
  const [isPass, setIsPass] = useState(false);
  const [auto, setAuto] = useState(true);
  const [passLink, setPassLink] = useState("");

  const getPass = async () => {
    let { data, error } = await supabase
    .from("passes")
    .select(`key, datetime, reason, status, pass_link`)
    .eq("recipient", uid);    
    if (data !== null && data.length > 0 && data[0].status === "pending") {
      const dateTime = new Date(data[0].datetime);
      const todayDate = new Date();
      
      if (todayDate.toDateString() === dateTime.toDateString()) {
        setAuto(false);
        setIsPass(true);
        setReason(data[0].reason);
        setFname(localStorage.getItem("fname"));
        setLname(localStorage.getItem("lname"));
        setKey(data[0].key);
        setPassLink(data[0].pass_link);
    
        const rawHours = dateTime.getHours();
        const minutes = dateTime.getMinutes();
        const timeSuffix = rawHours >= 12 ? "PM" : "AM";
        const displayHours = rawHours % 12 === 0 ? 12 : rawHours % 12;
    
        setTime(`${displayHours}:${minutes} ${timeSuffix}`);
    
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[dateTime.getMonth()];
        const year = dateTime.getFullYear();
        const day = dateTime.getDate();
    
        setDate(`${month} ${day}, ${year}`);
      } else {
        resetState();
      }
    } else {
      resetState();
    }
    
    function resetState() {
      console.warn("Sorry, that document doesn't exist!");
      console.error(error);
      setIsPass(false);
      setDate("");
      setTime("");
      setFname("");
      setKey("");
      setLname("");
      setReason("");
      setAuto(false);
    }
    
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Pass</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {isPass && (
          <div>
            <h1>Your Pass</h1>
            <h4>Date: {date}</h4>
            <h4>Time: {time}</h4>
            <h4>Reason: {reason}</h4>
            <h4>Key: {key}</h4>
            <h4>First Name: {fname}</h4>
            <h4>Last Name: {lname}</h4>
            <IonButton href={passLink}>View & Download Pass</IonButton>
            <h5>Click the link above to view your full, scannable pass. If you'd like, you can also download the pass to your phone's mobile wallet for quick access from your phone or smart watch!</h5>
          </div>
        )}
        {!isPass && (
          <div>
            <h1>No Pass</h1>
          </div>
        )}       
        <IonToast isOpen={showErrorToast} onDidDismiss={() => setShowErrorToast(false)} message="Unknown error. Please contact your school's admin before trying again." duration={2000} />
        <Menu/>
      </IonContent>
    </IonPage>
  );
};

export default PassPage;