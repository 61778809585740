import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonList, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { createClient } from '@supabase/supabase-js';

const DeleteAccountPage: React.FC = () => {

  const supabase = createClient(`${process.env.REACT_APP_supabase_url}`, `${process.env.REACT_APP_supabase_service}`, {
    auth: {
      autoRefreshToken: false,
      persistSession: false
    }
  })

  const uid = localStorage.getItem("uid")

  async function deleteAccount() {
    const { data, error } = await supabase.auth.admin.deleteUser(
      uid
    )
    if (!error){
      SignOut();
    }
  }

  const SignOut = async () => {
    const { error } = await supabase.auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
    if (error) {
      console.error(error);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Delete Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <h2>Are you sure you want to delete your account?</h2>
        <h4>This action is irreversible.</h4>
        <IonList>
          <IonButton fill="outline" expand="block" routerLink="/account">Nevermind</IonButton>
        </IonList>
        <IonList>
          <IonButton color="danger" fill="outline" expand="block" onClick={deleteAccount}>Delete Account</IonButton>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default DeleteAccountPage;