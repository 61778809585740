import { IonApp, IonButton, IonContent, IonHeader, IonImg, IonInput, IonItem, IonLabel, IonPage, IonRouterOutlet, IonSplitPane, IonTitle, IonToast, IonToolbar, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { v4 as uuidv4 } from "uuid";
import { Redirect, Route, useHistory } from 'react-router-dom';
import AccountPage from './pages/Account';
import UpdatePhonePage from './pages/Update Phone';
import RequestPage from './pages/Request';
import PassPage from './pages/Pass';
import AboutPage from './pages/About';
import AttendancePage from './pages/Attendance';
import UpdatePasswordPage from './pages/Update Password';
import LinkStudentPage from './pages/Link Student';
import DeleteAccountPage from './pages/Delete Account';
import AppUrlListener from './AppUrlListener';
import { useEffect, useState } from 'react';
import './App.tsx.css';
import supabase from './pages/supabase/supabaseClient';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
setupIonicReact();

const App: React.FC = () => {

  const [auto, setAuto] = useState(true);

  useEffect(() => {    
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      if (auto) {
        getUserInfoAuto();
      }
    })

    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event == "PASSWORD_RECOVERY") {
        const newPassword = prompt("What would you like your new password to be?");
        const { data, error } = await supabase.auth
          .updateUser({ password: newPassword })
 
        if (data) alert("Password updated successfully!")
        if (error) alert("There was an error updating your password.")
      }
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    })

    return () => subscription.unsubscribe()
  })  

  const [session, setSession] = useState(null);
  const [email, setEmail] = useState("");
  const [uid, setUid] = useState(uuidv4());
  const [password, setPassword] = useState("");
  const [accountType, setAccountType] = useState("student");
  const [showAccountErrorToast, setShowAccountErrorToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showFieldToast, setShowFieldToast] = useState(false);
  const [showConfirmEmailError, setShowConfirmEmailError] = useState(false);
  const [fatalError, setFatalError] = useState(false);

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [phone, setPhone] = useState("");

  const [checkEmail, setCheckEmail] = useState(false);
  const [setupAccount, setSetupAccount] = useState(false);
  const [authed, setAuthed] = useState(false);

  const [schoolInfo, setSchoolInfo] = useState([]);
  const [schools, setSchools] = useState([]);

  const isMobileBrowser = () => {
    const userAgent = window.navigator.userAgent;
    const mobileKeywords = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'IEMobile', 'Opera Mini'];
    
    return mobileKeywords.some(keyword => userAgent.includes(keyword));
  };

  const SignOut = async() => {
    const { error } = await supabase.auth.signOut();
    localStorage.clear()
    sessionStorage.clear();
    window.location.reload();
    if (error) {
      console.error(error);
    }
  }

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  const handleFnameChange = e => {
    setFname(e.target.value);
  };

  const handleLnameChange = e => {
    setLname(e.target.value);
  };

  const handlePhoneChange = e => {
    setPhone(e.target.value);
  };

  async function getOauth () {
    const user = supabase.auth.getUser();  
    setEmail((await user).data.user.email);
    setUid((await user).data.user.id);
  }

  async function doSetupAccount() {
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    if (error){
      console.error(error);
      setShowConfirmEmailError(true);
    }
    setSetupAccount(true);
  }

  async function getUserInfoAuto() {
    setAuto(false);
    const { data, error } = await supabase.auth.getUser();
    if (data){
      const user = data.user;
      if (user) {
        localStorage.setItem("uid", user.id);
        setUid(user.id);
        if (localStorage.getItem("type") === "parent") {
          getParentData(user.id);
        } else if (localStorage.getItem("type") === "student") {
          getStudentData(user.email);
        }else{
          console.error(error);
        }
      }else{
        console.error(error);
      }
    }else{
      console.error(error);
    }
  }

  async function checkProvider () {
    const user = supabase.auth.getUser();
    setSetupAccount(true);
    getOauth();
  }  

  async function signInWithEmail() {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    setAccountType("parent");
    localStorage.setItem("type", "parent");
    if (data) {
      const user = data.user;
      if (user) {
        localStorage.setItem("uid", user.id);
        localStorage.setItem("provider", "email");
        setUid(user.id);
        getParentData(user.id);
      } else {
        console.error("User is not logged in");
        setShowFieldToast(true);
      }
    } else {
      console.error(error);
      setShowFieldToast(true);
    }
  }

  async function signInWithOauth(provider) {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: provider,
    });
    if (error) {
      console.error(error);
    } else {
      setAccountType("parent");
      localStorage.setItem("type", "parent");
      localStorage.setItem("provider", "oauth");
    }
  }

  async function signInWithWorkOS() {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'workos',
      options: {
        queryParams: {
          organization: `${process.env.REACT_APP_workos_org}`
        },
      },
    });
    if (error) {
      console.error(error);
    } else {
      setAccountType("student");
      localStorage.setItem("type", "student");
    }
  } 
  
  async function signUp() {
    const { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
    })
    setAccountType("parent");
    localStorage.setItem("type", "parent");
    if (data) {
      localStorage.setItem("provider", "email");
      const user = data.user;
      if (user) {
      setUid(data.user.id);
      setCheckEmail(true);
      localStorage.setItem("signup", "true");
      } else {
        console.error("User is not logged in");
        console.error(error);
        setShowFieldToast(true);
      }
    } else {
      console.error(error);
      setShowFieldToast(true);
    }
  }

  async function getParentData(uid) {
    const { data, error } = await supabase
      .from("parent_accounts")
      .select('fname, lname, email, phone, device_token, uid, students')
      .eq('uid', uid);
  
    if (data === null || data.length === 0) {
      if (localStorage.getItem("provider") === "email") {
        if (localStorage.getItem("signup") === "true") {
          setAuto(false); 
          doSetupAccount();
          return;  
        } else {
          console.warn('No matching rows found/invalid account type');
          console.error(error);
          setFatalError(true)
          setShowAccountErrorToast(true);
        }
      } else if (localStorage.getItem("provider") === "oauth") {
        console.error(error);
        checkProvider();
      }
    } else {
      localStorage.setItem('device_token', data[0].device_token);
      localStorage.setItem('email', data[0].email);
      localStorage.setItem('fname', data[0].fname);
      localStorage.setItem('lname', data[0].lname);
      localStorage.setItem('phone', data[0].phone);
      localStorage.setItem("students", JSON.stringify(data[0].students));
  
      setAuthed(true);
      setAuto(false);
      const loggedInUserId = uid;
    }
  }  

  async function getStudentData(email) {
    const { data, error } = await supabase
      .from("student_accounts")
      .select('fname, lname, email, device_token, uid, school_id')
      .eq('email', email);
  
    if (data === null || data.length === 0) {
      console.warn('No matching rows found/invalid account type');
      console.error(error);
      setShowAccountErrorToast(true);   
      setAuto(false);
      setFatalError(true); 
      return;  
    } else {
      localStorage.setItem('device_token', data[0].device_token);
      localStorage.setItem('email', data[0].email);
      localStorage.setItem('fname', data[0].fname);
      localStorage.setItem('lname', data[0].lname);
      localStorage.setItem("school_id", data[0].school_id);
      localStorage.setItem("uid", data[0].uid);
  
      if (process.env.REACT_APP_classes) {
        getStudentClasses(data[0].uid);
      } else {
        setAuthed(true);
        const loggedInUserId = uid;
      }
    }
  }  

  async function getStudentClasses (suid) {
    let { data, error } = await supabase
      .from("student_accounts")
      .select(`parents, classes`)
      .eq("uid", suid);

    if (data !== null) {
      localStorage.setItem("students", JSON.stringify(data[0].parents));
      localStorage.setItem("classes", JSON.stringify(data[0].classes));
      setAuthed(true);
    }else{
      console.error(error);
    }
  }

  async function finishStudentSetup() {
    if (phone === ""){
      setShowFieldToast(true);
    }else if (phone === null){
      setShowFieldToast(true);
    }else if (phone.length === 10){
      const { error } = await supabase
        .from(`${accountType}`+"_accounts")
        .update({ phone: phone })
        .eq('uid', uid)
      if (error){
        console.error(error);
        setShowErrorToast(true);
      } else {
        localStorage.setItem("phone", phone);
        setAuthed(true);
      }
    }else{
      setShowFieldToast(true);
    }      
  }

  async function setData() {
    if (phone === ""){
      setShowFieldToast(true);
    }else if (phone === null){
      setShowFieldToast(true);
    }else if (phone.length === 10){
      const { error } = await supabase
      .from("parent_accounts")
      .insert({ device_token: null, email: email, fname: fname, lname: lname, phone: phone, uid: uid })
      if (error){
        console.error(error);
        setShowFieldToast(true);
      } else {
        getParentData(uid);
      }      
    }else{
      setShowFieldToast(true);
    }     
  }

  if (!session) {
    if (checkEmail === true){
      return (
        <IonPage>
          <AppUrlListener></AppUrlListener>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Check Email</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <h1>Please check your inbox and junk folders for a verification email.</h1>
            <h3>Note: Please open the verification email on this device. If you open it on another device, you'll receive an error on that device. Please close the app/website on that device and click the next button below.</h3>
            <IonButton  fill="outline"onClick={doSetupAccount} expand="block">Next</IonButton>
            <IonToast isOpen={showErrorToast} onDidDismiss={() => setShowErrorToast(false)} message="Unknown error. Please contact your school's admin before trying again." duration={2000} />
            <IonToast isOpen={showConfirmEmailError} onDidDismiss={() => setShowConfirmEmailError(false)} message="Please confirm your email first." duration={2000} />
          </IonContent>
        </IonPage>
      );
    } else{
      return (
        <IonPage>
          <AppUrlListener></AppUrlListener>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Sign In/Sign Up</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <h1 className='centered-text'>Parent Sign In/Sign Up</h1>
          <IonItem>
            <IonLabel position="floating">Email</IonLabel>
            <IonInput type="email" autocomplete="email" id="email" pattern="email" value={email} enterkeyhint="next" onIonChange={handleEmailChange}></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Password</IonLabel>
            <IonInput type="password" id="password" pattern="password" value={password} enterkeyhint="next" onIonChange={handlePasswordChange}></IonInput>
          </IonItem>
          <div className='button-container'>
            <IonButton  fill="outline"className='parent-button' expand="block" onClick={signInWithEmail}>Sign In</IonButton>
            <IonButton  fill="outline"className='parent-button' expand="block" onClick={signUp}>Sign Up</IonButton>  
          </div>

          <div>
              <h3 className="centered-text">Continue with a Third Party</h3>
              <div className="image-container centered-text-margin">
                <div onClick={() => signInWithOauth("apple")} className="circle-image-container">
                  <IonImg alt="unknown image" className="light-mode-image" src="/assets/SIWA/Logo - SIWA - Logo-only - White@3x.png" />
                  <IonImg alt="unknown image" className="dark-mode-image" src="/assets/SIWA/Logo - SIWA - Logo-only - Black@3x.png" />
                </div>
                <div onClick={() => signInWithOauth("google")} className="circle-image-container">
                  <IonImg alt="unknown image" className="light-mode-image image-border" src="/assets/SIWG/SIWG-dark.png" />
                  <IonImg alt="unknown image" className="dark-mode-image" src="/assets/SIWG/SIWG-light.png" />
                </div>
              </div>
              <h1 className="centered-text">Student Sign In</h1>
              <div className='button-container'>
                <IonButton  fill="outline"className='student-button' onClick={signInWithWorkOS} expand="block">Sign In with School</IonButton>
              </div>
            </div>

          <IonToast isOpen={showAccountErrorToast} onDidDismiss={() => setShowAccountErrorToast(false)} message="Error, incorrect account type. Please make sure you have the correct account type selected. Please contact your admin if error persists." duration={3000} />
          <IonToast isOpen={showFieldToast} onDidDismiss={() => setShowFieldToast(false)} message="Error. Please make sure you've filled out all fields, that your email and password are correct, and that you have an account. If you are a student please contact your admin for an account. If you are a parent please create your own account." duration={7000} />
        </IonContent>
      </IonPage>
      );
    }
  }
  else {      
    if(!authed){
      if (setupAccount){
        return (
          <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Setup Account</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <IonItem>
              <IonLabel position="floating">First Name</IonLabel>
              <IonInput type="text" autocomplete="given-name" id="fname" pattern="text" enterkeyhint="next" onIonChange={handleFnameChange}></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Last Name</IonLabel>
              <IonInput type="text" autocomplete="family-name" id="lname" pattern="text" enterkeyhint="next" onIonChange={handleLnameChange}></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Phone Number</IonLabel>
              <IonInput type="tel" autocomplete="tel" id="phone" pattern="tel" enterkeyhint="enter" onIonChange={handlePhoneChange}></IonInput>
            </IonItem>
            <IonButton  fill="outline"onClick={setData} expand="block">Finish</IonButton>
            <IonToast isOpen={showFieldToast} onDidDismiss={() => setShowFieldToast(false)} message="Error. Please make sure you've filled out all fields, that your email and password are correct, and that you have an account. If you are a student please contact your admin for an account. If you are a parent please create your own account." duration={7000} />
            <IonToast isOpen={showErrorToast} onDidDismiss={() => setShowErrorToast(false)} message="Unknown error. Please contact your school's admin before trying again." duration={2000} />
          </IonContent>
        </IonPage>
        );
      } else if (fatalError){
        return (
          <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Fatal Account Error</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <h1>Uh oh, looks like we hit a serious snag.</h1>
            <h3>Please contact your school's admin before trying again.</h3>  
            <h4>If you're receiving this error after clicking on a verification email, please return to the original device and proceed there.</h4>
            <IonButton  fill="outline"onClick={SignOut} expand="block">Reset App</IonButton>
          </IonContent>
        </IonPage>
        ); 
      } else {
        return (
          <IonPage>
            <IonHeader>
              <IonToolbar>
                <IonTitle>Please wait...</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
              <h1>Please wait for your account to load</h1>
              <h4>If this page displays for more than 10 seconds, please click the button below.</h4>
              <IonButton  fill="outline"onClick={SignOut} expand="block">Reset App</IonButton>
            </IonContent>
          </IonPage>
        );
      }
    } else if (authed){
      return (
        <IonApp>
          <IonReactRouter>
            <AppUrlListener></AppUrlListener>
            <IonSplitPane contentId="main">
              <IonRouterOutlet id="main">
                <Route path="/account" component={AccountPage} />
                <Route path="/request" component={RequestPage} />
                <Route path="/pass" component={PassPage} />
                <Route path="/updatephone" component={UpdatePhonePage} />
                <Route path="/about" component={AboutPage} />
                <Route path="/attendance" component={AttendancePage} />
                <Route path="/updatepassword" component={UpdatePasswordPage} />
                <Route path="/linkstudent" component={LinkStudentPage} />
                <Route path="/deleteaccount" component={DeleteAccountPage} />
                <Route render={() => <Redirect to="/account" />} />
                <Redirect exact from="/" to="/account" />
              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactRouter>
        </IonApp>
      );
    } else {
      return (
        <IonPage>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Please wait...</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <h1>Please wait for your account to load</h1>
            <h4>If this page displays for more than 10 seconds, please click the button below.</h4>
            <IonButton  fill="outline"onClick={SignOut} expand="block">Reset App</IonButton>
          </IonContent>
        </IonPage>
      ); 
    }
  }
};

export default App;