import { IonPage, IonHeader, IonTitle, IonToolbar, IonContent, IonButtons, IonBackButton, useIonViewDidEnter, IonSelectOption, IonItem, IonSelect, IonModal, IonButton } from '@ionic/react';
import Menu from './components/Menu';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import supabase from './supabase/supabaseClient';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const AttendancePage: React.FC = () => {

  const calendarRef = useRef(null);

  const history = useHistory();

  const [studentData, setStudentData] = useState([]);
  const [uid, setUid] = useState("");
  const [attendanceData, setAttendanceData] = useState (null);
  const [classes, setClasses] = useState([]);
  const [classIds, setClassIds] = useState([]);
  const [calendarKey, setCalendarKey] = useState(0);
  const [schoolData, setSchoolData] = useState([{"weekends": true, "start": "00:00", "end": "23:59"}]);
  const [showEventModal, setShowEventModal] = useState(false);
  const [classData, setClassData] = useState({name: null, starttime: null, endtime: null, status: null, reason: null})

  useEffect(() => {
    if (!process.env.REACT_APP_attendance) {
      history.push("/account");
    }
    setCalendarKey(calendarKey + 1);
  }, [attendanceData]);

  useIonViewDidEnter(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().updateSize();
    }
  });  

  async function fetchStudentData() {
    setUid(localStorage.getItem("uid"));
    getStudentClassesAndSchool(localStorage.getItem("uid"));
  }

  async function getStudentData() {
    let { data, error } = await supabase
      .from("student_accounts")
      .select("fname, lname, uid")
      .in("uid", JSON.parse(localStorage.getItem("students")));

    if (data === null || data.length === 0 || data[0].uid === null || error) {
      console.warn("No matching rows found/invalid account type");
      console.error(error);
    } else if (data !== null && data.length > 0 && data[0].uid !== null && !error) {
      // Map the data to an array of student objects
      const studentData = data.map((student) => ({
        uid: student.uid,
        fname: student.fname,
        lname: student.lname,
      }));  
      // Update the state variable with the new array
      setStudentData(studentData);
    }
  }

  async function getStudentClassesAndSchool(uid) {
    let { data, error } = await supabase
      .from("student_accounts")
      .select("classes, school_id")
      .eq("uid", uid);
  
    let formattedClassData = [];

    console.log(data);
    
    if (data !== null && data.length > 0 && data[0].classes !== null) {
      const classData = data[0].classes;
      console.log(classData);
      formattedClassData = Object.keys(classData).map((key) => ({
        period: key,
        class_id: classData[key],
      }));
      console.log(formattedClassData);
    } else {
      console.warn("No matching rows found/invalid account type");
      console.error(error);
    }
  
    const allClassIds = formattedClassData.map((classInfo) => classInfo.class_id);

    // Update the state variables with the new arrays
    setClasses(formattedClassData);
    setClassIds(allClassIds);
    fetchEvents(uid, allClassIds);
    getSchoolData(data[0].school_id);
  }  

  const getColor = (status) => {
    switch (status) {
      case "present":
        return "green";
      case "tardy":
        return "yellow";
      case "absent":
        return "red";
      default:
        return "red";
    }
  };

  async function fetchEvents(uid, class_ids) {
    const { data, error } = await supabase
      .from("attendance")
      .select("class_id, class_name, attendance, startdatetime, enddatetime")
      .eq("uid", uid)
      .in("class_id", class_ids);
    if (error) {
      console.error("Error fetching events:", error);
      return [];
    } else if (data) {
      const attendanceCalendarData = data.map((attendance) => ({
        id: attendance.class_id,
        title: attendance.class_name,
        start: attendance.startdatetime,
        end: attendance.enddatetime,
        color: getColor(attendance.attendance.status),
        reason: attendance.attendance.reason,
        status: attendance.attendance.status,
      }));
      setAttendanceData(attendanceCalendarData);
    } else {
      console.error("Datetime property not found in the first data object.");
    }
  }  

  async function getSchoolData(id) {
    let { data, error } = await supabase
      .from("school_info")
      .select("schedule_info")
      .eq("id", id);
  
    if (error) {
      console.error(error);
    } else if (data !== null && data.length > 0 && data[0].schedule_info !== null) {
      const schoolD = data.map((school) => ({
        id: school.schedule_info.class_id,
        start: school.schedule_info.schoolStart,
        end: school.schedule_info.schoolEnd,
        weekends: school.schedule_info.weekends,
      }));
      setSchoolData(schoolD);
    }
  }
  
  
  const handleStudentChange = e => {
    const selectedStudentId = e.target.value;
    setUid(selectedStudentId);
    getStudentClassesAndSchool(selectedStudentId);
    fetchEvents(selectedStudentId, classIds);
  };

  const handleClassChange = e => {
    const selectedClassIds = e.target.value;
    setClassIds(selectedClassIds);
    fetchEvents(uid, selectedClassIds);
  };  

  const handleEventClick = async (info) => {
    const searchDate = new Date(info.event.start);
    const foundEntry = attendanceData.find((entry) => {
      const clickedDate = new Date(entry.start);
      return clickedDate.getTime() === searchDate.getTime();
    });
    
    if (foundEntry) {
      setClassData({name: foundEntry.title, starttime: foundEntry.start, endtime: foundEntry.end, status: foundEntry.status, reason: foundEntry.reason});
      setShowEventModal(true);
    }    
  }  

  useEffect(() => {
    if (localStorage.getItem("type") === "parent") {
      getStudentData();
    } else if (localStorage.getItem("type") === "student") {
      fetchStudentData();
    }
  }, []);  

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Attendance</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {localStorage.getItem("type") === "parent" && (
          <div>
            <IonItem>
              <IonSelect interface="popover" placeholder="Select Student" id="student" value={uid} onIonChange={handleStudentChange}>
                {studentData.map((student) => (
                  <IonSelectOption key={student.uid} value={student.uid}>
                    {student.fname} {student.lname}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </div>
        )}
        {uid && (
          <div>
            <IonItem>
              <IonSelect interface="popover" placeholder="Select Class" id="class" value={classIds} onIonChange={handleClassChange} multiple={true}>
                {classes.map((classInfo) => (
                  <IonSelectOption key={classInfo.class_id} value={classInfo.class_id}>
                    {classInfo.class_id}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </div>
        )}
        <FullCalendar
          plugins={[ dayGridPlugin, timeGridPlugin ]}
          initialView="timeGridWeek"
          slotDuration='00:45'
          headerToolbar={{
            left: 'prev,next',  
            center: 'title',
            right: 'dayGridMonth,timeGridWeek'
          }}
          eventClick={handleEventClick}
          ref={calendarRef}
          key={calendarKey}
          allDaySlot={false}
          weekends={schoolData[0].weekends}
          slotMinTime={schoolData[0].start}
          slotMaxTime={schoolData[0].end}
          events={attendanceData}
        />
        <IonModal isOpen={showEventModal}>
          <IonContent>
            <h1>Class: {classData.name}</h1>
            <h1>Status: {classData.status}</h1>
            {classData.status !== "present" && (
            <h1>Reason: {classData.reason}</h1>
            )}
            <h1>Class Start: {new Date(classData.starttime).getHours()}:{new Date(classData.starttime).getMinutes()}, {new Date(classData.starttime).getMonth() + 1}-{new Date(classData.starttime).getDate()}-{new Date(classData.starttime).getFullYear()}</h1>
            <h1>Class End: {new Date(classData.endtime).getHours()}:{new Date(classData.endtime).getMinutes()}, {new Date(classData.endtime).getMonth() + 1}-{new Date(classData.endtime).getDate()}-{new Date(classData.endtime).getFullYear()}</h1>
            <IonButton  fill="outline"onClick={() => setShowEventModal(false)} expand="block">Close</IonButton> 
          </IonContent>
        </IonModal>
        <Menu/>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(AttendancePage); // Wrapped component with React.memo
