import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import Menu from './components/Menu';

const AboutPage: React.FC = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>About App</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      <h1>{process.env.REACT_APP_name}</h1>  
        <h3>App Flavor: {process.env.REACT_APP_flavor}</h3>
        <h3>App Toppings: {process.env.REACT_APP_toppings}</h3>
        <h3>Made with ♥️ and 🍕 in Atlanta, Georgia</h3>
        <IonButton  fill="outline"href='https://myattendancedesk.org' expand="block">My AttendanceDesk Website</IonButton>
        <h1>Found a problem, or need help? Visit our support center:</h1>
        <IonButton  fill="outline"href='https://help.myschooldesk.org/support/home' expand="block">My SchoolDesk Support</IonButton>
        <Menu/>
        <h1>User Information</h1>
        <h3>Name: {localStorage.getItem('fname')} {localStorage.getItem('lname')}</h3>
        <h3>User ID: {localStorage.getItem('uid')}</h3>
      </IonContent>
    </IonPage>
  );
};

export default AboutPage;