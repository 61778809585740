import { IonPage, IonHeader, IonTitle, IonToolbar, IonContent, IonButtons, IonInput, IonItem, IonLabel, IonList, IonButton, IonToast, IonBackButton } from '@ionic/react';
import { useState } from 'react';
import Menu from './components/Menu';
import supabase from './supabase/supabaseClient';

const UpdatePhonePage: React.FC = () => {

  const [phone, setPhone] = useState("");
  const uid = localStorage.getItem("uid");
  const type = localStorage.getItem("type");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showLengthToast, setShowLengthToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const updatePhone = async () => {
    if (phone !== ""){
      const { error } = await supabase
      .from(`${type}`+"_accounts")
      .update({ phone: phone })
      .eq('uid', uid)
      if (error){
        console.error(error);
        setShowErrorToast(true);
      } else {
        localStorage.setItem("phone", phone);
        setShowSuccessToast(true);
      }    
    } else {
      setShowLengthToast(true);
    }
  }

  const handlePhoneChange = e => {
    setPhone(e.target.value)
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Update Phone Number</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonItem>
            <IonLabel position="floating">New Phone Number</IonLabel>
            <IonInput type="tel" autocomplete="tel" id="phone" pattern="tel" enterkeyhint="enter" onIonChange={handlePhoneChange}></IonInput>
          </IonItem>
          <IonButton  fill="outline"expand="block" onClick={updatePhone}>Update Phone Number</IonButton>
        </IonList>
        <IonToast isOpen={showErrorToast} onDidDismiss={() => setShowErrorToast(false)} message="Unknown error. Please contact your school's admin before trying again." duration={2000} />
        <IonToast isOpen={showLengthToast} onDidDismiss={() => setShowLengthToast(false)} message="Error. Please make sure you've entered a phone number." duration={2000} />
        <IonToast isOpen={showSuccessToast} onDidDismiss={() => setShowSuccessToast(false)} message="Success! Your phone number has been updated." duration={2000} />
        <Menu/>
      </IonContent>
    </IonPage>
  );
};

export default UpdatePhonePage;