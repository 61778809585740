import { IonPage, IonHeader, IonTitle, IonToolbar, IonContent, IonButtons, IonInput, IonItem, IonLabel, IonList, IonButton, IonToast, IonBackButton } from '@ionic/react';
import { useState } from 'react';
import Menu from './components/Menu';
import supabase from './supabase/supabaseClient';
import React from 'react';

const LinkStudentPage: React.FC = () => {

  const [OTC, setOTC] = useState(0);
  const [error, setError] = useState("");
  const uid = localStorage.getItem("uid");
  const students = localStorage.getItem("students");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showLengthToast, setShowLengthToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  async function getStudentID() {
    if (OTC.toString().length === 8){
      const { data, error } = await supabase
        .from('otcs')
        .select('student_id, claimed')
        .eq('otc', OTC) 
      if (error){
        console.error(error);
        setError(error.message);
        setShowErrorToast(true);
      } else if (data[0].claimed === false){
        if (Object.values(students).indexOf(data[0].student_id) <= -1) {
          getCurrentStudent(data[0].student_id);
        } else {
          setShowErrorToast(true);
          setError('Student is already associated.');
        }
      } else {
        setShowErrorToast(true);
        setError("Please enter a valid OTC");
      }
    } else {
      setShowLengthToast(true);
    }
  }

  async function getCurrentStudent(id) {
    console.log('getstudent');
    const { data, error } = await supabase
      .from ('student_accounts')
      .select ('parents')
      .eq('uid', id)    
    if (data[0].parents === null){
      const parents = [uid];
      updateStudent(id, parents);
    } else if (Object.values(data[0].parents).indexOf(uid) <= -1) {
      console.log(data[0].parents);
      console.log(uid);
      const parents = data[0].parents;
      parents.push(uid);
      updateStudent(id, parents);
    } else {
      setShowErrorToast(true);
      setError('Parent is already associated.');
    }
  }

  async function updateStudent(id, parents) {
    const { data, error } = await supabase
      .from('student_accounts')
      .update({ parents: parents })
      .eq('uid', id)
      .select()
    updateParent(id);
  }

  async function updateParent(id) {
    const objStudents = JSON.parse(students);
    if (objStudents === null){
      const students = [id];
      const { data, error } = await supabase
        .from('parent_accounts')
        .update({ students: students })
        .eq('uid', uid)
        .select()
    } else {
      const students = objStudents;
      objStudents.push(id);
      const { data, error } = await supabase
        .from('parent_accounts')
        .update({ students: students })
        .eq('uid', uid)
        .select()
    }
    updateOTCRecord();
  }

  async function updateOTCRecord() {
    const { data, error } = await supabase
      .from('otcs')
      .update({ claiming_parent: uid, claimed: 'TRUE' })
      .eq('otc', OTC)
      .select()
    if (!error) {
      setShowSuccessToast(true);
    } else {
      setShowErrorToast(true);
      setError(error.message);
    }
  }

  const handleOTCChange = e => {
    setOTC(e.target.value)
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Link Student</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonItem>
            <IonLabel position="floating">OTC</IonLabel>
            <IonInput type="number" id="OTC" pattern="number" enterkeyhint="enter" inputmode="numeric" onIonChange={handleOTCChange}></IonInput>
          </IonItem>
          <IonButton  className='button' expand="block" onClick={getStudentID}>Link Student</IonButton>
        </IonList>
        <IonToast isOpen={showErrorToast} onDidDismiss={() => setShowErrorToast(false)} message={error} duration={2000} />
        <IonToast isOpen={showLengthToast} onDidDismiss={() => setShowLengthToast(false)} message="Error. Please make sure you've entered an 8-digit OTC." duration={2000} />
        <IonToast isOpen={showSuccessToast} onDidDismiss={() => setShowSuccessToast(false)} message="Success! Your accounts have been linked. Note that you and your student student will need to log out and log back into your apps for changes to appear." duration={5000} />
        <Menu/>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(LinkStudentPage);